@import "../../styles/title";
@import "../../styles/InputTextarea";
@import "../../styles/button";
@import "../../styles/button_cancel";
@import "../../styles/variables";

.overlay {
  grid-area: mapcontent;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001 !important;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: hidden;
  .transportFormContainer {
    position: absolute;
    left: 60%;
    top: 50%;
    transform: translate(-60%, -50%);
    .transportForm {
      border-radius: 4px;
      width: 452px;
      max-height: 460px;
      background-color: $BgColor;
      padding: 32px;

      .formHeader {
        display: flex;
        align-items: center;
        @include Title(24);
        img {
          width: 16px;
          height: 16px;
          margin-left: auto;
          cursor: pointer;
        }
      }
      .formDescription {
        padding-top: 8px;
        .descriptionBlock {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          label {
            font-weight: 400;
            font-size: 16px;
            line-height: 148%;
          }
          input {
            width: 100%;
            height: 40px;
            border: 1px solid #646464;
            padding: 12px;
            border-radius: 2px;
            outline: none;
            /* &.active {
              border: 2px solid $Color11;
            }*/
          }
          .input_field {
            border: 2px solid #ec4c47;
          }
          .text-field__icon {
            position: relative;
          }
          .text-field__img {
            position: absolute;
            display: flex;
            align-items: center;
            top: 0;
            bottom: 0;
            right: 0.5rem;
            width: 2rem;
            cursor: pointer;
            svg {
              &:hover {
                path {
                  fill: #215ca7;
                }
              }
            }
          }
        }
        p {
          color: #ec4c47;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          /* &::before {
            display: inline;
            content: "⚠ ";
          }*/
        }
      }
      @include Btn(11.25, 2.5);
      @include BtnCancel(180px, 40px);
    }
  }
}

@import "../../../styles/variables";
@import "../../../styles/title";
@import "../../../styles/modal_buttons";
.modal {
  grid-area: mapcontent;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  .modalContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .modalForm {
    padding: 32px 32px 20px 32px;
    width: 452px;
    height: 160px;
    background-color: $BgColor;
    border-radius: 4px;
    @include Title(24);
    @include BtnModal;
  }
}
